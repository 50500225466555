.myVideo {
  position:absolute;
  z-index: 0;
  object-fit:cover;
  width: 100vw;
  opacity: 80%;
}

.scrollingPic {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.bgBlack {
  background: rgb(2,0,36);
  background: linear-gradient(51deg, rgba(2,0,36,1) 0%, rgba(29,29,29,1) 0%, rgba(126,126,126,1) 100%);
  }

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

.font-roboto {
  font-family: 'Roboto';
}

.font-sans-he {
  font-family: 'Noto Sans Hebrew';
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
